import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Search from '../components/search'
import { renderPostsList } from '../utils'

const PostList = ({ data, pageContext: { currentPage, numPages } }) => {
  const posts = data.allMarkdownRemark.edges
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
  const nextPage = "/blog/" + (currentPage + 1).toString()

  return (
    <Layout title="Blog Pablo Cianes">
      <div className="main-content post-list-main mt-3 p-3">
        <h1 className="heading mb-5">Blog</h1>
        <section id="search">
          <Search indices={[{ name: `Blog`, title: `Artículos` }]} />
        </section>
        {renderPostsList(posts)}
        <div className="text-center mt-5">
          {!isFirst && (
            <Link to={prevPage} rel="prev" style={{ textDecoration: `none` }}>
              <span className="text-dark">← Página anterior</span>
            </Link>
          )}
          {!isLast && (
            <Link to={nextPage} rel="next" style={{ textDecoration: `none` }}>
              <span className="text-dark ml-5">Página siguiente →</span>
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const listQuery = graphql`
         query paginateQuery($skip: Int!, $limit: Int!) {
           allMarkdownRemark(
             limit: $limit
             skip: $skip
             sort: { fields: [frontmatter___date], order: DESC }
             filter: { frontmatter: { published: { eq: true } } }
           ) {
             totalCount
             edges {
               node {
                 excerpt(pruneLength: 200)
                 html
                 id
                 frontmatter {
                   title
                   date
                   tags
                 }
                 fields {
                   slug
                 }
               }
             }
           }
         }
       `

export default PostList
